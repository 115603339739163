import { useState, useRef } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

import {  CustomImage } from "./GalleryImages";
import images from "../assets/images.json"
export const GalleryGrid = ({ filter = "Todas" }: { filter?: string }) => {
  const [index, setIndex] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const galleryRef = useRef<HTMLDivElement>(null);
  const lightboxRef = useRef<any>(null);

  const filteredPhotos = images.filter(
    (photo) => filter === "Todas" || photo.category === filter
  );

  const currentImage = filteredPhotos[index];
  const nextIndex = (index + 1) % filteredPhotos.length;
  const nextImage = filteredPhotos[nextIndex] || currentImage;
  const prevIndex = (index + filteredPhotos.length - 1) % filteredPhotos.length;
  const prevImage = filteredPhotos[prevIndex] || currentImage;

  const handleClick = (index: number, item: CustomImage) => {
    if (!isOpen) {
      setIsOpen(true);
      setIndex(index);
    }
  };

   // eslint-disable-next-line
  const handleDownload = async () => {
    if (!currentImage) return;

    try {
      const response = await fetch(currentImage.downloadLink);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `${
        currentImage.caption || "image"
      }.${currentImage.downloadLink.split(".").pop()}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIndex(-1);
  };

  const handleMovePrev = () => {
    setIndex(prevIndex);
  };

  const handleMoveNext = () => {
    setIndex(nextIndex);
  };

  return (
    <div className="container mx-auto p-3">
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body p-2" ref={galleryRef}>
          <Gallery
            images={filteredPhotos}
            onClick={handleClick}
            enableImageSelection={false}
          />
        </div>
      </div>
      {isOpen && currentImage && (
        <Lightbox
          ref={lightboxRef}
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
          discourageDownloads={true}
          animationDuration={300}
          reactModalStyle={{
            overlay: {
              zIndex: 1500,
              ariaHideApp: false,
            },
          }}
        />
      )}
    </div>
  );
};
