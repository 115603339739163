import { useState } from "react";

 const categories = ["Todas","Decoração", "Bastidores", "Recepção", "Cerimônia","Familia e Amigos","Portraits", "Festa", "Leonardo"];

export const GalleryFilters = ({
  onFilterChange,
}: {
  onFilterChange: (category: string) => void;
}) => {
  const [activeFilter, setActiveFilter] = useState("Todas");

  const handleFilterClick = (category: string) => {
    setActiveFilter(category);
    onFilterChange(category);
  };

  return (
    <div className="flex flex-wrap justify-center gap-2 my-8 px-4">
      {categories.map((category) => (
        <button
          key={category}
          onClick={() => handleFilterClick(category)}
          className={`btn btn-sm ${
            activeFilter === category
              ? "btn-primary"
              : "btn-ghost"
          }`}
        >
          {category}
        </button>
      ))}
    </div>
  );
};