import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Gallery from "../pages/gallery/GalleryIndex";
import Execute from "../pages/commands/Execute";
import Invitations from "../pages/commands/Invitation";
 

const router = createBrowserRouter([
  {
    path: "/",
    Component: Gallery,
    loader: ({ params }) => {
      // da para carregar api aqui ver depois os detalhes de como fazer
      return false;
    },
	// Children precisa do outlet para rodar como no landing page ta sem a barra de navegacao nao pega esses caras daqui
    children: [],
  },
  {
    path: "exec/*",
    Component: Execute,
    children: [
		{ index:true,  path: "invitations/:action", Component: Invitations },
 
	],
  },
]);

const RouteRender = () => <RouterProvider router={router} />;

export default RouteRender;
