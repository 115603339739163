import { Heart } from "lucide-react";

export const GalleryHeader = () => {
  return (
    <header className="hero min-h-[40vh] bg-base-200">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <div className="badge badge-primary gap-2 mb-4">
            <Heart size={14} className="text-accent" />
            Isabela & Yuri - 14/09/2024
          </div>
          <h1 className="text-5xl font-bold  mb-4">  Uma historia de amor aventura e magia!</h1>
          <p className="text-lg opacity-70">
          Coleção de momentos de nossa grande aventura!
          </p>
        </div>
      </div>
    </header>
  );
};