import { useState } from "react";
import { GalleryHeader } from "../../components/GalleryHeader";
import { GalleryFilters } from "../../components/GalleryFilters";
import { GalleryGrid } from "../../components/GalleryGrid";
import Theme from "../../components/ThemeController";

const Gallery = () => {
  const [activeFilter, setActiveFilter] = useState("Todas");

  return (
    <div className="min-h-screen bg-base-200">
        <div className="py-2 mx-2">
        <Theme />
        </div>

      <GalleryHeader />
      <GalleryFilters onFilterChange={setActiveFilter} />
      <GalleryGrid filter={activeFilter} />
    </div>
  );
};

export default Gallery;